<template>

	
<div></div>
  
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
   // HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #ececec; /* Color de fondo según la imagen */
}

.hero-section {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.logo {
  font-size: 30px; /* Tamaño de fuente simulando el logo */
  color: #999; /* Color de fuente simulando el logo */
  margin-bottom: 20px;
}

.hero-text {
  font-size: 2em; /* Tamaño de fuente del título */
  color: #333; /* Color de fuente del título */
  margin: 0 0 10px; /* Espaciado alrededor del título */
}

.hero-subtext {
  color: #666; /* Color de fuente del subtítulo */
  margin: 0 0 20px; /* Espaciado alrededor del subtítulo */
}

.btn {
  background-color: #FF00FF; /* Color de fondo del botón */
  color: white; /* Color de fuente del botón */
  padding: 10px 20px; /* Relleno alrededor del texto del botón */
  text-decoration: none; /* Ninguna decoración de texto para el enlace */
  border: none; /* Sin borde para el botón */
  outline: none; /* Sin contorno para el botón */
  font-size: 1em; /* Tamaño de fuente del botón */
  cursor: pointer; /* Cursor tipo puntero para indicar que es clickeable */
  border-radius: 4px; /* Bordes redondeados para el botón */
}

</style>
